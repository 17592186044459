import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import MainLayout from './components/MainLayout';
import { ParallaxProvider } from 'react-scroll-parallax';

import Home from './pages/Home';
import CoomingSoon from './pages/CoomingSoon';

function App() {
    return (
        <ParallaxProvider>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<CoomingSoon/>}/>
                    {/* <Route element={<MainLayout />}>
                        <Route path='/' element={<Home/>}/>
                    </Route> */}
                </Routes>
            </BrowserRouter>
        </ParallaxProvider>
    )
}

export default App;
