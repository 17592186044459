import { Button, Col, Container, Row } from "react-bootstrap";
import { Parallax } from 'react-scroll-parallax';
import logo from "../assets/img/logo-full.png"

const CoomingSoon = () => {
    return(
        <div>
            <Container>
                <div className="main-content">
                    <Parallax speed={-20} className="main-parallax-3"/>
                    <Parallax speed={30} className="main-parallax-2"/>
                    <Parallax speed={-15} className="main-parallax-1"/>
                    <img src={logo} width={300} alt=""/>
                    <div className="content-wrapper fade-in-text">
                        <div className="title mt-5">SEAMLESS OUTSOURCING SOLUTION</div>
                        <div className="subtitle mt-5" style={{ fontSize:30 }}>Cooming Soon</div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default CoomingSoon